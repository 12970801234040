import React from 'react';

import _ from 'lodash';

import { stringifyParams } from 'utils';

import {
  buildFilters,
  getInitialFilters,
  getFilters,
  getFiltersFromUrl,
  FILTERS_KEY
} from 'utils/filters';
import {
  getInitialPagination,
  getPagination,
  getPaginationFromUrl,
  updatePagination,
  buildPagination,
  buildPaginationForFrontendUrl,
  PAGINATION_KEY,
  getInitialCursorPagination,
  updateCursorPagination,
  buildCursorPagination,
  normalizeCursorPaginationResponse,
  INITIAL_CURSOR_PAGINATION_STATE,
  getCursorPaginationFromUrl
} from 'utils/pagination';

import {
  getInitialSort,
  getSort,
  updateSort,
  buildSort,
  SORT_KEY,
  getSortFromUrl
} from 'utils/sort';

const DEFAULT_LIMIT = 15;
const DEFUALT_PAGE_SIZE = 50;

const ListViewEssentials = options => {
  const limit = _.get(options, 'limit', DEFAULT_LIMIT);
  const defaultSort = _.get(options, 'sort', null);
  const pageSize = _.get(options, 'pageSize', DEFUALT_PAGE_SIZE);
  const cursorPagination = _.get(options, 'cursorPagination', false);
  const urlUpdatesEnabled = _.get(options, 'urlUpdatesEnabled', true);

  return WrappedComponent => {
    class ListViewPaginationHOC extends React.Component {
      state = {
        ...getInitialPagination(limit),
        ...getInitialFilters(),
        ...getInitialSort(defaultSort),
        data: null,
        response: null,
        isFetching: true,
        hasExcludedFilters: false,
        excludedCount: null,
        totalCount: null
      };

      updatePagination = (paginationData, callback = () => {}) => {
        this.setState(prevState => {
          const newPagination = updatePagination(prevState, paginationData);

          return {
            ...prevState,
            [PAGINATION_KEY]: newPagination
          };
        }, callback);
      };

      updateFilters = (filters, callback = () => {}) => {
        this.setState(prevState => {
          return {
            ...prevState,
            [FILTERS_KEY]: filters
          };
        }, callback);
      };

      updateSort = (field, callback = () => {}) => {
        this.setState(prevState => {
          const sort = updateSort(prevState, field);

          return {
            ...prevState,
            [SORT_KEY]: sort
          };
        }, callback);
      };

      initialize = () => {
        const url = urlUpdatesEnabled ? this.props.history.location.search : '';

        const pagination = urlUpdatesEnabled
          ? getPaginationFromUrl(url, limit)
          : getPagination(this.state);
        const filters = urlUpdatesEnabled
          ? getFiltersFromUrl(url)
          : getFilters(this.state);
        const sort = urlUpdatesEnabled
          ? getSortFromUrl(url)
          : getSort(this.state);

        this.updatePagination(pagination, () => {
          this.updateFilters(filters, () => {
            this.setState(prevState => {
              return {
                ...prevState,
                [SORT_KEY]: sort
              };
            }, this.callFetch);
          });
        });
      };

      callFetch = async () => {
        this.setState({ isFetching: true });

        let sdk = options.sdk;
        const passPropsToSdk = options.passPropsToSdk || false;

        const pagination = getPagination(this.state);
        const paginationParams = buildPagination(pagination);
        const filters = getFilters(this.state);
        const filterParams = buildFilters(filters);
        const sort = getSort(this.state);
        const sortParams = buildSort(sort);
        const params = {
          ...paginationParams,
          ...filterParams,
          ...sortParams
        };

        if (passPropsToSdk) {
          sdk = sdk(this.props);
        }

        const { data, success } = await sdk(params);

        if (success) {
          this.updatePagination({
            count: data.count ?? data.length
          });
          if ('has_excluded_filters' in data) {
            this.setState({
              hasExcludedFilters: data.has_excluded_filters,
              excludedCount: data.excluded_count,
              totalCount: data.total_count
            });
          }
          /* If list view is not paginated, no results are present. Get whole data in this case. */

          this.setState({
            data: _.get(data, 'results', data),
            response: data,
            count: data.count ?? data.length,
            isFetching: false
          });
        }
      };

      componentDidMount() {
        this.initialize();
      }

      changeUrl = (additionalParam = {}) => {
        // Get only needed params
        if (!urlUpdatesEnabled) return;

        const pagination = buildPaginationForFrontendUrl(
          getPagination(this.state)
        );
        const filters = getFilters(this.state);
        const sort = buildSort(getSort(this.state));

        const params = { ...pagination, ...filters, ...sort };

        const p = value => _.isNil(value) || value === '';

        const paramsWithValue = _.pickBy(params, _.negate(p));

        this.props.history.replace({
          search: stringifyParams(paramsWithValue)
        });
      };

      filterBy = filters => {
        // Always open first page when new filter is applied
        this.updatePagination({ page: 1 }, () => {
          this.updateFilters(filters, () => {
            this.changeUrl();
            this.callFetch();
          });
        });
      };

      addFilters = filters => {
        const currentFilters = getFilters(this.state);
        this.filterBy({ ...currentFilters, ...filters });
      };

      clear = () => {
        this.updatePagination(
          getInitialPagination(limit)[PAGINATION_KEY],
          () => {
            this.updateFilters(getInitialFilters()[FILTERS_KEY], () => {
              this.setState(
                prevState => {
                  return {
                    ...prevState,
                    ...getInitialSort()
                  };
                },
                () => {
                  this.changeUrl();
                  this.callFetch();
                }
              );
            });
          }
        );
      };

      changePage = page => {
        this.updatePagination({ page }, () => {
          this.changeUrl({ page });
          this.callFetch();
        });
      };

      orderBy = field => {
        this.updateSort(field, () => {
          this.changeUrl();
          this.callFetch();
        });
      };

      changeRowsPerPage = limit => {
        this.updatePagination({ limit, page: 1 }, () => {
          this.changeUrl({ limit, page: 1 });
          this.callFetch();
        });
      };

      render() {
        const {
          data,
          response,
          isFetching,
          hasExcludedFilters,
          excludedCount,
          totalCount
        } = this.state;

        const { count, page, limit } = getPagination(this.state);
        const filters = getFilters(this.state);
        const sort = getSort(this.state);

        return (
          <WrappedComponent
            {...this.props}
            data={data}
            response={response}
            count={count}
            page={page}
            limit={limit}
            filters={filters}
            sort={sort}
            filterBy={this.filterBy}
            addFilters={this.addFilters}
            orderBy={this.orderBy}
            changePage={this.changePage}
            changeRowsPerPage={this.changeRowsPerPage}
            clear={this.clear}
            refetch={this.callFetch}
            isFetching={isFetching}
            initialize={this.initialize}
            hasExcludedFilters={hasExcludedFilters}
            excludedCount={excludedCount}
            totalCount={totalCount}
          />
        );
      }
    }

    class ListViewCursorPaginationHOC extends React.Component {
      state = {
        ...getInitialCursorPagination(pageSize),
        ...getInitialFilters(),
        ...getInitialSort(defaultSort),
        data: null,
        response: null,
        isFetching: true
      };

      updatePagination = (paginationData, callback = () => {}) => {
        this.setState(
          prevState => ({
            ...prevState,
            [PAGINATION_KEY]: updateCursorPagination(prevState, paginationData)
          }),
          callback
        );
      };

      updateFilters = (filters, callback = () => {}) => {
        this.setState(prevState => {
          return {
            ...prevState,
            [FILTERS_KEY]: filters
          };
        }, callback);
      };

      updateSort = (field, callback = () => {}) => {
        this.setState(prevState => {
          const sort = updateSort(prevState, field);

          return {
            ...prevState,
            [SORT_KEY]: sort
          };
        }, callback);
      };

      initialize = () => {
        const url = urlUpdatesEnabled ? this.props.history.location.search : '';

        const pagination = urlUpdatesEnabled
          ? getCursorPaginationFromUrl(url, pageSize)
          : getPagination(this.state);
        const filters = urlUpdatesEnabled
          ? getFiltersFromUrl(url)
          : getFilters(this.state);
        const sort = urlUpdatesEnabled
          ? getSortFromUrl(url)
          : getSort(this.state);

        this.updatePagination(pagination, () => {
          this.updateFilters(filters, () => {
            this.setState(prevState => {
              return {
                ...prevState,
                [SORT_KEY]: sort
              };
            }, this.callFetch);
          });
        });
      };

      callFetch = async () => {
        this.setState({ isFetching: true });

        let sdk = options.sdk;
        const passPropsToSdk = options.passPropsToSdk || false;

        const pagination = getPagination(this.state);
        const paginationParams = buildCursorPagination(pagination);
        const filters = getFilters(this.state);
        const filterParams = buildFilters(filters);
        const sort = getSort(this.state);
        const sortParams = buildSort(sort);
        const params = {
          ...paginationParams,
          ...filterParams,
          ...sortParams
        };

        if (passPropsToSdk) {
          sdk = sdk(this.props);
        }

        const { data, success } = await sdk(params);

        if (success) {
          this.updatePagination(normalizeCursorPaginationResponse(data));

          /* If list view is not paginated, no results are present. Get whole data in this case. */
          this.setState({
            data: _.get(data, 'results', data),
            response: data,
            count: data.count ?? data.length,
            isFetching: false
          });
        }
      };

      componentDidMount() {
        this.initialize();
      }

      changeUrl = (additionalParam = {}) => {
        if (!urlUpdatesEnabled) return;

        // NOTE: There is not point in storing the pagination cursor in the URL
        // because the cursor cannot be used for the same request.
        const { pageSize } = getPagination(this.state);

        const filters = getFilters(this.state);
        const sort = buildSort(getSort(this.state));

        const params = { ...filters, ...sort, page_size: pageSize };

        const p = value => _.isNil(value) || value === '';

        const paramsWithValue = _.pickBy(params, _.negate(p));

        this.props.history.replace({
          search: stringifyParams(paramsWithValue)
        });
      };

      filterBy = filters => {
        // Always open first page when new filter is applied
        this.updatePagination(
          {
            ...INITIAL_CURSOR_PAGINATION_STATE,
            pageSize: getPagination(this.state).pageSize
          },
          () => {
            this.updateFilters(filters, () => {
              this.changeUrl();
              this.callFetch();
            });
          }
        );
      };

      addFilters = filters => {
        const currentFilters = getFilters(this.state);
        this.filterBy({ ...currentFilters, ...filters });
      };

      clear = () => {
        this.updatePagination(INITIAL_CURSOR_PAGINATION_STATE, () => {
          this.updateFilters(getInitialFilters()[FILTERS_KEY], () => {
            this.setState(
              prevState => {
                return {
                  ...prevState,
                  ...getInitialSort()
                };
              },
              () => {
                this.changeUrl();
                this.callFetch();
              }
            );
          });
        });
      };

      changePage = currentCursor => {
        this.updatePagination({ currentCursor }, () => {
          this.callFetch();
        });
      };

      changeRowsPerPage = pageSize => {
        this.updatePagination(
          { ...INITIAL_CURSOR_PAGINATION_STATE, pageSize },
          () => {
            this.changeUrl();
            this.callFetch();
          }
        );
      };

      orderBy = field => {
        this.updateSort(field, () => {
          this.changeUrl();
          this.callFetch();
        });
      };

      render() {
        const { data, response, isFetching } = this.state;

        const { nextCursor, previousCursor, pageSize } = getPagination(
          this.state
        );
        const filters = getFilters(this.state);
        const sort = getSort(this.state);

        return (
          <WrappedComponent
            {...this.props}
            data={data}
            response={response}
            filters={filters}
            sort={sort}
            nextCursor={nextCursor}
            previousCursor={previousCursor}
            pageSize={pageSize}
            filterBy={this.filterBy}
            addFilters={this.addFilters}
            orderBy={this.orderBy}
            changePage={this.changePage}
            changeRowsPerPage={this.changeRowsPerPage}
            clear={this.clear}
            refetch={this.callFetch}
            isFetching={isFetching}
            initialize={this.initialize}
          />
        );
      }
    }

    return cursorPagination
      ? ListViewCursorPaginationHOC
      : ListViewPaginationHOC;
  };
};

export default ListViewEssentials;
