import _ from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';

import { Page, Header, Filters as SharedFilters } from 'components';
import { PERMISSIONS } from 'permissions';

import { withPermissions, withLoggedUser, useFilters } from 'hooks';

import { STEPS } from 'pages/PriceSeries/Update/utils';

import { PriceSeriesFrequencyTable } from './components';

import { FREQUENCIES, FILTERS as LAYOUT_FILTERS } from './utils';

function PriceSerieUpdate({ user, crumbs, history }) {
  const { productSlug } = useParams();

  const { filters, filterBy, clear } = useFilters({ history });

  const frequencies = _.castArray(filters['frequency'] || FREQUENCIES);
  const disabled = false;

  return (
    <Page user={user}>
      <Page.Body>
        <Page.Content maxWidth crumbs={crumbs}>
          <Header>Price Series Update</Header>

          <SharedFilters
            filters={filters}
            filterBy={filterBy}
            clearFilters={clear}
            layout={LAYOUT_FILTERS(productSlug)}
          />

          {frequencies.map(frequency => (
            <PriceSeriesFrequencyTable
              frequency={frequency}
              user={user}
              filters={filters}
              filterBy={filterBy}
              disabled={disabled}
              steps={STEPS[frequency.toLowerCase()]}
            />
          ))}
        </Page.Content>
      </Page.Body>
    </Page>
  );
}

export default withLoggedUser(
  withPermissions([PERMISSIONS.VIEW_PRICES])(PriceSerieUpdate)
);
